body {
    margin: 0;
    padding: 0;
    font-family: sans-serif;
}

.appBar{
    background-color: #011D34 !important;
    /* background-image: url('http://www.puertadeoro.org/wp-content/uploads/2018/04/bg-proyectos.jpg?id=96') !important; */
    background-position-y: center;
}

.ExpanPanelSumary > * {
    margin: 0 !important;
}

.button-add-suppliers {
    color: white !important;
    background-color: #61d461!important;
}

.button-add-suppliers > .MuiButton-label {
    text-decoration: none !important;
}

.iconEditContact {
    font-size: 16px !important;
    width: 15px;
    background: #e2e1e1;
    border-radius: 50%;
    padding: 3px;
    left: calc(100% - 55px);
    position: relative;
    top: 2px;
    z-index: 99999999999999999;
}

.iconDellContact{
    font-size: 16px !important;
    width: 15px;
    background: #e2e1e1;
    border-radius: 50%;
    padding: 3px;
    left: calc(100% - 50px);
    position: relative;
    top: 2px;
    z-index: 99999999999999999;
}

.iconEditNote {
    background-color: #d4d3d3;
    border-radius: 15px;
    padding: 2px;
    height: 18px !important;
    width: 18px !important;
    float: right;
    margin-top: 10px;
}

.icon{
    background-color: #d4d3d3;
    border-radius: 15px;
    padding: 2px;
    height: 18px !important;
    width: 18px !important;
}

.flat-button-file{
    color:black;
    background-color:transparent;
    padding: 9px;
    border: 1px solid rgb(197, 197, 197);
    border-radius: 5px;
}
.cardContact{
    padding-bottom: 8px;
    margin-bottom: 27px;
}
.img_logo{
    position: absolute;
    width: 100px !important;
    height: 100px;
    margin-left: 18px;
    margin-top: 9px;
}

.card_title{
    position: absolute;
    margin-left: 100px;
    margin-top: -86px;
}

.star_shareholder{
    position: absolute;
    margin-left: 178px;
    margin-top: -103px;
}

.cardAction{
    background-color: #fafafa !important;
    color: #3d383f;
}

.chip_card{
    background-color: #FAFAFA !important;
    margin: 2px;
    border-color: #707070 !important;
    padding-top: 1px;
    margin-top: 15px;
    height: 22px !important;
}

.card-supplier-top{
    display: flex
}

.card-supplier-top-text{
    margin-left: 10px;
    flex-grow: 1;
}

.card-supplier-top-star{
    display: flex;
    color: #ecb826;
}

.card-supplier-top-start-hidden{
    display:none;
}

.bold-color-main{
    color:#707070;
    font-weight: bold;
}

.color-text{
    color:#707070;
}

.cardContent-chips{
    padding-top: 0px !important;
}

.chip_card_category{
    background-color: transparent !important;
    margin: 2px;
    padding-top: 1px;
    margin-top: 15px;
    height: 22px !important;
    font-weight: bold;
    color: #3D383F;
}

.EditCategory{
    cursor: pointer;
    color: blue;
    font-weight: normal;
}

.DellCategory{
    cursor: pointer;
    color: red;
    font-weight: normal;
}

.chip_green{
    margin: 2px;
    padding-top: 1px;
    margin-top: 15px;
    height: 22px !important;
    background-color: #61d461 !important;
    color: white !important;
}

.menuItems{
    color: white !important;
}

.menuItems:active{
    color: #f3b229 !important;
}

.sidebar{
    background-color: #000d18 !important;
}

.text-center{
    text-align: center;
}

.contendIcons{
    margin-bottom: -28px;
}

.cardContent{
    padding-left: 8px !important;
    color: #3D383F;
    text-decoration: none !important;
}
a:hover{
    text-decoration: none !important;
}

.cardContactHeader{
    background-color: #fafafa!important;
    color: rgb(61, 56, 63);
}

.btn-add-category{
    margin-top: 15px !important;
}

.panelDasboard{
    background-color: rgb(249, 249, 249) !important;
}

.width-600{
    width: 600px !important;
}

[class *= "MuiDialog-paperWidthSm"]{
    max-width: 650px !important;
}

.modal-category>div {
    overflow: visible;
}

.contend-modal-add-category{
    overflow-y: visible !important;
}

.chip-p{
    border-radius: 25px;
    padding: 4px 10px ;
    display: flex;
    align-items: center;

}

.chips-ps{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.font-bold{
    font-weight: bold;
}

.logo-supplier{
    object-fit: "contain" !important;
    background-color: black;
    height:"90px" !important;
    width:"90px" !important;
}

.main-card{
    height: 210px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.cardAction{
    height: 100px;
}

.icon-edit-supplier-logo{
    position: absolute;
    background-color: #d4d3d3;
    border-radius: 15px;
    padding: 2px;
    margin-left: 75px;
    margin-top: -8px;
    height: 18px !important;
    width: 18px !important;
}

.expancionPanelMain{
    margin-bottom: 10px;
}

.expansionPanelHeader{
    background-color: #fafafa !important;
}

.checkBoxDasboard{
    margin-top: -14px !important;
    margin-left: -8px !important;
}

.iconDeleteSupplier{
    background-color: #d4d3d3;
    border-radius: 15px;
    padding: 2px;
    position: absolute;
    /* margin-left: 305px; */
    margin-top: -30px;
    height: 18px !important;
    width: 18px !important;
    margin-left: 30px;
}

.vefiried_approved{
    color: #61d461;
}

.icons-actions{
    margin-top: -32px;
    position: absolute;
    margin-left: 310px;
}

.content-notes{
    BACKGROUND: WHITE;
   PADDING: 1PX 14px;
   MARGIN-BOTTOM: 5PX;
   border-radius: 5px;
}

.content-notes > p{
    margin-top: 15px;
    word-break: break-all;
}

.btn-download-file{
    margin-left: 20px !important;
}

.show-files{
    display: flex !important;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 8px;
    padding-top: 8px;
}

.search-suppliers > label{
    color: #000;
    font-size: 18px;
    overflow-x: inherit !important;
}
.text-name-suppliers{
    color: black !important;
}
.capitalize{
    text-transform: capitalize;
}
.mt-15{
    margin-top: 15px;
}